@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', 'sans-serif';
  background-color: #eff3f7;
}

.App {
  height: 100lvh;
  background-color: #eff3f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:focus {
  outline: 1px solid black;
  margin-top: 1px;
  box-shadow: none !important;
}

.fc-event {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

iframe {
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

.video-embed-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 100%;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

#school-info-container img,
.notices-dialog img {
  max-width: 100%;
  height: auto;
}

#school-info-container * {
  word-wrap: break-word;
}

#school-info-container h1,
#school-info-container h2,
#school-info-container h3,
#school-info-container h4,
#school-info-container h5,
#school-info-container h6 {
  color: #906d4f;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 6px;
}

.address-finder-input {
  height: 50px;
  padding-bottom: 0;
  padding-left: 20px;
}

/* Calendar */
.fc {
  background-color: #ffffff;
}

.fc table {
  border-top: none !important;
}

.fc .fc-day-today {
  background-color: #c0cedb !important;
}

.fc-col-header-cell.fc-day {
  background-color: #e9e2dc !important;
  border: none !important;
  border-spacing: unset !important;
  border-collapse: unset !important;
}

th.fc-timegrid-axis {
  background-color: #e9e2dc !important;
  border: none !important;
}

.fc-timeGridWeek-view.fc-view.fc-timegrid .fc-scrollgrid.fc-scrollgrid-liquid {
  border: none !important;
}

[data-navlink],
.fc-col-header-cell.fc-day {
  font-weight: 500;
  color: #906d4f;
}

.fc-header-toolbar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fc-toolbar-title {
  color: #1f3263 !important;
  font-size: 20px !important;
}

.fc-toolbar-chunk .fc-button-group {
  background-color: #1f3263 !important;
  border-radius: 4px;
}

.fc-toolbar-chunk .fc-button:not(.fc-today-button) {
  background-color: transparent;
  font-weight: 300;
  font-size: 14px !important;
}

.fc-toolbar-chunk .fc-button-active {
  background-color: rgba(0, 0, 0, 0.6) !important;
  color: #ffffff !important;
  font-weight: 700;
}

.fc-today-button {
  font-weight: 300 !important;
  font-size: 14px !important;
}

@media only screen and (min-width: 768px) {
  .fc-header-toolbar {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
